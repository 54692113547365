import _ from "lodash";
import { useRef, useState } from "react";
import "./App.css";

export interface PreviewOption {
  name: string;
  physicalSize: number;
  height: number;
  width: number;
}

export const previewOptions: PreviewOption[] = [
  { name: "iPhone 13 Pro", physicalSize: 6.1, height: 960, width: 445 }, //2.17
  { name: "iPad 10", physicalSize: 10, height: 960, width: 667 }, //1.44
  { name: '1080p 15"', physicalSize: 15, height: 960, width: 540 }, //1.78
  { name: '1080p 22"', physicalSize: 22, height: 960, width: 540 }, //1.78
  { name: '1080p 27"', physicalSize: 27, height: 960, width: 540 }, //1.78
  { name: '4k 43"', physicalSize: 43, height: 960, width: 540 }, //1.78
  { name: '4k 55"', physicalSize: 55, height: 960, width: 540 }, //1.78
];

function App() {
  const [screenOrientation, setScreenOrientation] = useState("portrait");
  const [screenSize, setScreenSize] = useState<number>(15);
  const windowRef = useRef<Window | undefined | null>(undefined);

  const handleButtonClick = (isDisplayMode: boolean) => {
    let url = `https://preview.cloudshelf.ai/Z2lkOi8vY2xvdWRzaGVsZi9DbG91ZHNoZWxmLzAxSEtRVkRINk1YOE4zOUhDUVkwMUJFTVQx?forceScreenSize=${screenSize}`;
    if (isDisplayMode) {
      url += `&previewDisplayMode=true`;
    }

    const selectedOption = _.find(
      previewOptions,
      (option: PreviewOption) => option.physicalSize === screenSize
    );

    if (selectedOption === undefined) {
      console.error("Something went wrong, unable to find the option");
      return;
    }

    let width = selectedOption.width;
    let height = selectedOption.height;

    if (screenOrientation === "landscape") {
      width = selectedOption.height;
      height = selectedOption.width;
    }

    windowRef.current?.close();

    windowRef.current = window.open(
      url,
      "_blank",
      `left=25,top=25,width=${width},height=${height},resizable=off`
    );

    windowRef.current?.focus();
  };

  return (
    <div className="container">
      <div className="row">
        <div className="column">
          <label htmlFor="screenOri">Screen Orientation:</label>
          <select
            id="screenOri"
            className="minimal"
            value={screenOrientation}
            onChange={(e) => setScreenOrientation(e.target.value)}
          >
            <option value="portrait">Portrait</option>
            <option value="landscape">Landscape</option>
          </select>
        </div>
        <div className="column">
          <label htmlFor="screenSize">Screen Size:</label>
          <select
            id="screenSize"
            className="minimal"
            value={screenSize}
            onChange={(e) => setScreenSize(parseFloat(e.target.value))}
          >
            {_.map(previewOptions, (option: PreviewOption) => {
              return (
                <option key={option.physicalSize} value={option.physicalSize}>
                  {option.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <button
            className="button-accent"
            onClick={() => {
              handleButtonClick(false);
            }}
          >
            Start Demo
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
